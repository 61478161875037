@font-face {
  font-family: 'Nunito';
  src: url('assets/Nunito-Regular.ttf') format('woff');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: white !important;
  min-height: 100vh;
}

button {
  font-family: 'Nunito' !important;
}

html {
  scroll-behavior: smooth !important;
}

#middle {
  overflow-x: hidden;
}

@media (max-width: 949px) {
  .fake-a-tag {
    font-weight: 600 !important;
  }
}

.fake-a-tag {
  font-family: Nunito;
  font-size: 15px !important;
  line-height: 21px !important;
  color: #000000;
  text-decoration: none;
}

.fake-a-tag:hover,
.fake-a-tag:focus {
  color: #fe5d00;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  margin: 0px 0;
}

hr#vr-main {
  margin-right: 16px;
  height: 3em;
}

textarea {
  font: normal 300 1rem Nunito !important;
  font-size: 16px;
  color: #000000;
}

/*
 *  STYLE 1
 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fe5d00;
}

.header-div {
  width: 70vw !important;
  margin: auto !important;
}

.body-div {
  width: 75vw;
  margin: auto;
}

.orangeDiv {
  margin-bottom: 1em;
}

div#div-main-buttons {
  width: 100% !important;
}

.box-ops {
  width: 20vw;
  margin: auto;
}

.centralize {
  align-items: center;
  display: flex;
  min-height: 100%;
}

.whiteSet {
  background-color: '#FFFFFF';
  height: 0;
}

.selected-page {
  background: transparent;
  color: #fe5d00 !important;
  cursor: pointer;
}

.videoPlayer {
  width: 100%;
  margin: auto;
}

.dropzone {
  height: 100%;
  min-height: 20vh !important;
  border: 1px solid #ebebeb !important;
  background: #ebebeb 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 2px #6c6c6c;
  border-radius: 10px;
}

.dropzoneImage {
  min-height: 190px !important;
}

.dropzone-doc {
  height: 200px !important;
  min-height: 20vh !important;
  border: 1px solid #ebebeb !important;
  background: #ebebeb 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 2px #6c6c6c;
  border-radius: 10px;
  min-width: 130px !important;
}

.dropzoneImage-doc {
  min-height: 150px !important;
  max-height: 150px !important;
  min-width: 130px !important;
}

.dropzoneImage-doc .MuiDropzoneArea-icon {
  display: none;
}

.dropzoneImage-doc .MuiDropzoneArea-textContainer {
  display: flex;
  justify-content: center;
}

.dropzoneImage-doc .MuiDropzoneArea-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dropzoneImage-doc.vencido {
  background: #fff3cc !important;
  border-color: #ffcc2f !important;
}

.dropzoneImage-doc.ok {
  background: #e2fffa !important;
  border-color: #07c8a9 !important;
}

.dropzone-document-container {
  position: relative;
}

.dropzone-document-container:hover .dp-icon {
  display: block;
}

.dropzoneImage-doc.faltando {
  background: #f1f1f1 !important;
  border-color: #6c6c6c !important;
}

.dropzoneImage-doc-ap {
  min-height: 140px !important;
  max-height: 140px !important;
}

.gridDropzoneImage {
  height: 190px;
}

.gridDropzoneImage .dropzoneImage .MuiDropzonePreviewList-root {
  margin: 0;
  height: 190px;
  min-width: 100%;
  position: absolute;
  top: 0vh;
  background-color: #ffffff !important;
}

.MuiDropzonePreviewList-imageContainer p {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.gridDropzoneImage
  .dropzoneImage
  .MuiDropzonePreviewList-root
  .MuiDropzonePreviewList-imageContainer {
  display: flex;
  height: 100%;
  min-width: 100%;
  padding: 18px;
  color: #c6c6c6 !important;
  box-shadow: #00000000 0 1px 6px, rgb(0 0 0 / 0%) 0 1px 4px !important;
}

.gridDropzoneImage
  .dropzoneImage
  .MuiDropzonePreviewList-root
  .MuiDropzonePreviewList-imageContainer
  img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.gridDropzoneImage
  .dropzoneImage
  .MuiDropzonePreviewList-root
  .MuiDropzonePreviewList-imageContainer
  .MuiDropzonePreviewList-removeButton {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
  height: 30px;
  top: -10px;
  right: -10px;
}

.dropzone .MuiDropzoneArea-textContainer {
  display: flex !important;
  width: 100%;
  height: 100%;
  min-height: 20vh;
}

.dropzoneImage .MuiDropzoneArea-textContainer {
  display: flex !important;
  width: 100%;
  height: 100%;
  min-height: 100% !important;
}

.MuiDropzoneArea-textContainer:focus {
  outline: none;
}

.dropzone:focus {
  outline: none;
}

.text-center {
  text-align: center;
}

.dropzone p {
  color: #6c6c6c;
  text-align: center;
  font: normal 300 0.85rem Nunito !important;
  font-weight: 700;
  margin: auto 8px;
}

.dropzone img {
  object-fit: cover;
  width: 100%;
}

.MuiDataGrid-colCellTitle {
  font-weight: 600 !important;
}

.dropzone .MuiDropzoneArea-icon {
  display: none;
}

.dropzone .MuiDropzonePreviewList-imageContainer {
  min-width: 175px;
  margin: auto;
}

.dropzone .MuiGrid-spacing-xs-8 {
  width: 100%;
  padding: 5px;
  margin: 16px 0;
}

.timelineLeft {
  display: flex;
  padding: 0 !important;
  width: 100%;
  min-width: 100%;
  margin-left: 15px;
  margin-bottom: 15px;
}

.timelineLeft::before {
  flex: 0 !important;
  padding: 0 !important;
}

.timelineMiddle {
  margin-left: 15px;
  margin-bottom: 15px;
}

.timelineMiddle::before {
  flex: 1 !important;
  padding: 6px 0 !important;
}

.timelineRight {
  display: flex;
  padding: 0 !important;
  width: 100%;
  min-width: 100%;
  margin-left: 15px;
  margin-bottom: 15px;
}

.timelineRight::before {
  flex: 0 !important;
  padding: 0 !important;
}

.lastFakeButton {
  margin-right: 0 !important;
}

.div-area-text {
  border: 1px solid #00000029;
  border-radius: 5px;
}

.area-button {
  color: #808080c7 !important;
}

.active-area-button {
  color: #000000 !important;
}

.area-text {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 15px;
  box-shadow: 0px 0px 0px #6c6c6c;
  /* border: 1px solid #00000029;
  border-radius: 5px; */
  outline: none;
  resize: none;
  font-weight: 400;
}

.area-text:focus {
  outline: none;
}

.text-adapt {
  font-family: Nunito;
  margin: auto !important;
  font-size: 15px !important;
  color: #000000 !important;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  display: block !important;
  padding: 2em 2em 2em 1.5em !important;
  text-transform: uppercase !important;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
}

.text-adapt:hover {
  background-color: rgba(254, 93, 0, 0) !important;
}

.over-option {
  color: #000000 !important;
  text-transform: none;
}

.over-option:hover {
  color: #fe5d00 !important;
}

.menu-dynamic:hover {
  background-color: unset !important;
}

.header-standand {
  padding: 0.4em 0 !important;
}

.swal2-container {
  z-index: 1000000 !important;
}

.text-menu {
  font-size: 16px !important;
  margin-bottom: 3px !important;
}

@media (min-width: 950px) {
  .text-adapt {
    padding: 0 !important;
    margin-left: 3em !important;
  }
}

p,
blockquote,
li[data-slate-node='element'] {
  text-align: start /* !important */;
}

span[contenteditable='false'] {
  position: inherit !important;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 100vw !important;
  }

  .orangeDiv {
    max-height: 20%;
    min-height: 20%;
  }
}

@media (min-width: 1600px) {
  .header-div {
    width: 55% !important;
    margin: auto !important;
  }

  .preloaderCard {
    height: 15em !important;
    width: 17em !important;
  }
}

@media (max-width: 900px) {
  .preloaderCard {
    width: 100% !important;
    margin: auto !important;
  }
}

@media (min-width: 950px) {
  .site-nav-open {
    width: auto !important;
  }
}

@media (max-width: 700px) {
  .header-div {
    width: 90vw !important;
    margin: auto !important;
  }

  .mob {
    top: -8em !important;
  }

  .body-div {
    margin: auto;
  }

  .orangeDiv {
    margin-bottom: 5em;
    height: auto;
    min-height: 15em;
    max-height: 30em;
  }

  .fit-div {
    min-height: 25em;
    max-height: 30em;
  }

  .fit-div-challenges {
    min-height: 20em;
    max-height: 33em;
  }

  .buttons-group {
    padding: 12px 8px;
    margin: auto;
    width: 100%;
  }

  #middle {
    /* mudar para whiteDIV */
    margin-bottom: 1.5em;
  }

  .box-ops {
    width: 80vw;
    margin: auto;
  }

  hr#vr-main {
    display: none;
  }

  div#div-main-buttons {
    display: grid;
  }

  div.button-div {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div#new-button {
    width: 92%;
    min-width: 92%;
    max-width: 92%;
    margin: 16px auto;
  }

  div.inner-div-main-buttons {
    margin: auto !important;
  }

  footer {
    margin: 0px !important;
  }
}

@media (max-width: 425px) {
  .lastFakeButton {
    margin-right: auto !important;
  }

  .orangeDiv {
    height: auto;
    min-height: 15em;
    max-height: 30em;
  }

  .fit-div {
    min-height: 30em;
    max-height: 33em;
  }

  .fit-div-challenges {
    min-height: 28em;
    max-height: 33em;
  }
}

@media (max-width: 350px) {
  .xs-screen {
    display: contents !important;
  }

  .fit-div-challenges {
    min-height: 28em;
    max-height: 38em;
  }
}

.carousel.carousel-slider {
  overflow: unset !important;
}

.carousel .control-dots {
  bottom: -40px !important;
}

@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: -40px !important;
  }
}

.carousel .control-dots .dot {
  box-shadow: unset !important;
  background: #d9d9d9 !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #fe5d00 !important;
}

.carousel.carousel-slider .control-arrow {
  background: rgb(0 0 0 / 66%) !important;
}

.carousel.carousel-slider .control-arrow {
  top: 100% !important;
  color: #000 !important;
  font-size: 12px !important;
  bottom: -40px !important;
  margin-top: unset !important;
  padding: unset !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000 !important;
}

.carousel.carousel-slider .control-arrow {
  background: none !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 300px;
  /* change this to whatever you want */
  max-width: 100%;
  object-fit: contain;
}

.rti--container {
  --rti-bg: '#fff';
  --rti-border: #c6c6c6 !important;
  --rti-main: #c6c6c6 !important;
  --rti-radius: '0.375rem';
  --rti-s: '0.5rem';
  /* spacing */
  --rti-tag: #fe5d01 !important;
  --rti-tag-remove: '#e53e3e';
}

.rti--tag {
  color: #ffffff !important;
  cursor: pointer !important;
}

.rti--tag > button {
  color: #ffffff !important;
}

.rti--input:focus {
  --rti-border: red !important;
  border: none !important;
}

.MuiTableCell-root {
  padding: 8px !important;
  font-size: 14px !important;
}
